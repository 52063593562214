/** Customer Dashboard **/
const baseURLCustomerDash = `https://zelt-auth.moshimoshi.cloud`
// const baseURLCustomerDash = `http://localhost:3050`
export const baseURLOrder = `https://zelt-order.moshimoshi.cloud`
// export const baseURLOrder = `http://localhost:3003`
export const baseURLprofile = `https://zelt-auth.moshimoshi.cloud`
// export const baseURLprofile = `http://localhost:3050`
export const baseURLcart = `https://zelt-cart.moshimoshi.cloud`
// export const baseURLcart = `http://localhost:3005`
// export const baseURLProduct = `https://zelt-product.moshimoshi.cloud`
export const baseURLProduct = `http://localhost:3002`

export default baseURLCustomerDash
